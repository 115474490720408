import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "bootstrap/dist/css/bootstrap.min.css"

const SizingGuide = () => {
  return (
    <Layout>
      <Seo title="Sizing Guide" />
      {/* title */}
      <section className="container py-5">
        <div className="row">
          <div className="col-md-8">
            <h1 className="bank-font gold-color">Sizing Guide</h1>
            <h4 className="text-white">
              Use the following guides to best find the right size for the
              product(s) you wish to purchase.
            </h4>
          </div>
        </div>
      </section>
      {/* rings */}
      <section className="container mt-3 pt-3 pb-5">
        <div className="row justify-content-between align-items-center text-white">
          <div className="col-md-8">
            <p className="h2 h1-bank-font">Ring Sizing Guide (US sizing)</p>
            <p className="minimal-body text-white">
              We’d recommend the following guide in order to determine your ring
              size if you don’t know it.
            </p>
            <ul>
              <li>
                Print this{" "}
                <a
                  href={"/guides/rings/ring_sizer.pdf"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  PDF
                </a>{" "}
                sizer at 100% scale.
              </li>
              <li>
                Cut the measuring tool & tightly wrap it around your desired
                finger, and note the number that is closest to the "Read Size
                Here" line as that will be your size.
              </li>
              <li>
                Another way would be to place a ring that you already have &
                fits you very well on the sizing circles until you find the
                match. Note the circle should fit within the ring perfectly.
              </li>
              <li>
                In the case you find you're in between sizes, it's recommended
                to round up.
              </li>
            </ul>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default SizingGuide
